<template>
  <div>
    <div class="fb-style web-background firstSectionPadFix">
      <div class="fb-grid">
        <div class="fb-content">
          <h1>Website Development</h1>
          <h3>
            Create websites that are fast, to the point, <br />easy to navigate
            and visible to searches.
          </h3>
          <router-link to="/contact"
            ><a
              ><button class="fb-content-btn">Connect with us</button></a
            ></router-link
          >
        </div>
        <img src="@/assets/images/Icon_material-web.svg" />
      </div>
    </div>

    <div class="label labelColorGrey">
      <div class="hrdiv hrdiv-grey-color"></div>
      <div class="pdiv">
        <p>&nbsp;Website Development</p>
      </div>
    </div>

    <div class="SecondBannerWrapper labelColorGrey">
      <div class="gridSBW">
        <div class="FirstWrap">
          <div class="wrap">
            <h2>Website development</h2>
            <p>
              We can take your existing website and customize it as you wish to
              have the needed functionality, while keeping the existing
              functionality and appearance intact.
            </p>
          </div>
          <div class="wrap">
            <h2>Wordpress</h2>
            <p>
              Wordpress is the most user system for website building. Its
              stability and ecosystem makes it great for customization of any
              sort of website, such as sale of services and brand pages.
            </p>
          </div>
          <div class="wrap">
            <h2>Shopify</h2>
            <p>
              Shopify is specialized in e-commerce, and is the most used
              platform for online stores in the world. It also holds features to
              integrate its point of sale with its online system, increasing the
              usability for any store that also does commerce online.
            </p>
          </div>
          <div class="wrap">
            <h2>Fully customized</h2>
            <p>
              Using tools such as HTML/CSS, VueJS, and/or Angular, any website
              with any features can be created to cater to our clients needs.
            </p>
          </div>
          <!-- <div class="wrapP ">
          <p>
            Our Developers have created hundreds of websites through these two
            systems.
          </p>
        </div> -->
          <div class="wrap wrap-btn">
            <router-link to="/contact"
              ><button class="blk btn">Get in touch</button></router-link
            >
          </div>
        </div>
        <div class="SecondWrap">
          <h2>Platforms we excel in</h2>
          <div class="platforms">
            <h4 id="category">Single page web apps</h4>
            <h4 id="hideInWeb">Single page web apps</h4>
            <ul>
              <li class="comma"><span> - </span>VueJS</li>
              <li class="comma"><span> - </span>Angular</li>
            </ul>
            <h4 id="category">Static Site Creation</h4>
            <h4 id="hideInWeb">Static Site Creation</h4>
            <ul>
              <li class="comma"><span> - </span>HTML/CSS</li>
            </ul>
            <h4 id="hideInWeb">WordPress</h4>
            <h4 id="hideInWeb">Shopify</h4>
            <h4 id="category">Other CMS</h4>
            <ul>
              <li id="category" class="comma">WordPress</li>
              <li id="category" class="shop">Shopify</li>
              <li class="comma"><span> - </span>Shopify</li>
              <li><span> - </span>Shopify+</li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <MainWhatWeDo />
    <Testimonials />
    <WhoWeAre />
    <div class="trustedby">
      <Trustedby />
    </div>
  </div>
</template>

<script>
import Testimonials from "../components/Testimonials.vue";
import MainWhatWeDo from "../components/MainWhatWeDo.vue";
import WhoWeAre from "./WhoWeAre.vue";
import Trustedby from "../components/Trustedby.vue";
export default {
  name: "Webdevelopment",
  title: "Web Development",
  components: { Testimonials, MainWhatWeDo, WhoWeAre, Trustedby },
};
</script>

<style scoped>
@import "../assets/css/web.css";
@import "../assets/css/tablet.css";
@import "../assets/css/mobile.css";
.trustedby {
  background: #f8f8f8;
}
</style>